<template>
  <div class="app">
    <router-view />
  </div>
</template>
<script>



</script>
<style lang="scss">
@font-face {
  font-family: "PingFangRegular";
  src: url('https://wugecdn.steam.fun/resources/static/PingFang%20Regular.ttf');

}

.app {
  font-family: 'PingFangRegular';

}
</style>
