import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// 导入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import animated from 'animate.css' // npm install animate.css --save安装，在引入
import 'hover.css'




createApp(App).use(store).use(router).use(ElementPlus).use(animated).mount('#app')
