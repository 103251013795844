import { createRouter, createWebHistory } from 'vue-router'

const routes = [

  {
    path: '/',
    name: 'home',
    component: () => import('../views/home')
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../views/home')
  },

  {
    path: '/system',
    name: 'system',
    component: () => import('../views/system')
  },
  {
    path: '/platform',
    name: 'platform',
    component: () => import('../views/platform')
  },
  {
    path: '/college',
    name: 'college',
    component: () => import('../views/college')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about')
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes,

})
router.beforeEach((to, from, next) => {
  // 兼容chrome
  document.body.scrollTop = 0
  // 兼容firefox
  document.documentElement.scrollTop = 0
  // 兼容safari
  window.pageYOffset = 0
  next()
})

export default router
